import React, { useState } from 'react';
import './app.sass'
import './style.sass'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css'
import Home from './pages/home/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'rc-slider/assets/index.css';
import AllForms from './pages/accelerate-pages/all-form/all-form';
import FirstTimeLogin from './pages/accelerate-pages/project-dashboard/components/first-time-login/first-time-login';
import Acceleratethanks from './pages/accelerate-pages/accelerate-thanks/accelerate-thanks';
import Accelerateonboard from './pages/accelerate-pages/accelerate-onboard/accelerate-onboard';
import Acceleratelists from './pages/accelerate-pages/accelerate-lists/accelerate-lists';
import Projectdashboard from './pages/accelerate-pages/project-dashboard/project-dashboard';
import VCDashboard from './pages/accelerate-pages/vc-dashboard/vc-dashboard';
import PublicProfileView from './pages/accelerate-pages/project-dashboard/components/public-profile-view/public-profile-view';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import { Login } from './pages/accelerate-pages/login/login';
import RouteLayout from './RoutesLayout';
import { PageNotFound } from './shared/page-not-found/page-not-found';
import VCOrMentorProfile from './pages/accelerate-pages/vc-dashboard/components/public-profile/public-profile';
import Blogs from './pages/blogs/Blogs';
import Blog from './pages/blogs/Blog';
import JobBoard from './pages/accelerate-pages/project-dashboard/components/job-board/JobBoard';
import Events from './pages/events/Events';

export const PrivateRoute = ({ children, routeName }: any) => {
  const authorization = localStorage.getItem("authorization") || ""
  const userType = localStorage.getItem("userTypeVal") || ""
  const projectPagesRoutesArray = ["/forms/:id", "/forms/:id/:id", "/project-thanks", "/project-lists", "/project-courses", "/project/:id", "/project/:id/:id", "/project/:id/:id/:id"]
  const mentorPagesRoutesArray = ["/mentor-dashboard", "/mentor-dashboard/:type", "/mentor-dashboard/:type/:type"]
  const partnerPagesRoutesArray = ["/partner-dashboard", "/partner-dashboard/:type", "/partner-dashboard/:type/:type"]
  const vcPagesRoutesArray = ["/vc-dashboard", "/vc-dashboard/:type"]
  if (userType) {
    if (userType === JSON.stringify("project") && authorization && projectPagesRoutesArray.includes(routeName)) {
      return children;
    } else if (userType === JSON.stringify("mentor") && authorization && mentorPagesRoutesArray.includes(routeName)) {
      return children;
    } else if (userType === JSON.stringify("vc") && authorization && vcPagesRoutesArray.includes(routeName)) {
      return children;
    } else if (userType === JSON.stringify("partner") && authorization && partnerPagesRoutesArray.includes(routeName)) {
      return children;
    } else {
      return <Navigate to="/page-not-found" />
    }
  } else {
    return <Navigate to="/login" />;
  }
};

function App() {
  const [value, setValue] = useState(0);
  return (
    <>
      <div className='baselayout__content'>
        <Router>
          <Routes>
            <Route path="/" element={<RouteLayout />}>
              <Route path="/" element={<Home />}></Route>
              <Route path="/blogs" element={<Blogs />}></Route>
              <Route path="/events" element={<Events />}></Route>
              <Route path="/events/:slug" element={<Events />}></Route>
              {/* <Route path="/jobs" element={<JobBoard />}></Route>
              <Route path="/jobs/:id" element={<JobBoard />}></Route> */}
              <Route path="/b/:slug" element={<Blog />}></Route>
              <Route path="/contact-form/:id" element={<AllForms />}></Route>

              <Route path="/forms/:id" element={<PrivateRoute routeName={"/forms/:id"}><FirstTimeLogin /></PrivateRoute>}></Route>
              <Route path="/forms/:id/:id" element={<PrivateRoute routeName={"/forms/:id/:id"}><FirstTimeLogin /></PrivateRoute>}></Route>

              <Route path="/project-thanks" element={<PrivateRoute routeName={"/project-thanks"}><Acceleratethanks /></PrivateRoute>}></Route>
              <Route path="/project-lists" element={<PrivateRoute routeName={"/project-lists"}><Acceleratelists /></PrivateRoute>}></Route>
              <Route path="/project-courses" element={<PrivateRoute routeName={"/project-courses"}><Accelerateonboard /></PrivateRoute>}></Route>
              {/* <Route path="/project" element={<PrivateRoute routeName={"/project"}><Projectdashboard /></PrivateRoute>}></Route> */}
              <Route path="/project/:id" element={<PrivateRoute routeName={"/project/:id"}><Projectdashboard /></PrivateRoute>}></Route>
              <Route path="/project/:id/:id" element={<PrivateRoute routeName={"/project/:id/:id"}><Projectdashboard /></PrivateRoute>}></Route>
              <Route path="/project/:id/:id/:id" element={<PrivateRoute routeName={"/project/:id/:id/:id"}><Projectdashboard /></PrivateRoute>}></Route>

              <Route path="/vc-dashboard" element={<PrivateRoute routeName={"/vc-dashboard"}><VCDashboard /></PrivateRoute>}></Route>
              <Route path="/vc-dashboard/:type" element={<PrivateRoute routeName={"/vc-dashboard/:type"}><VCDashboard /></PrivateRoute>}></Route>

              <Route path="/mentor-dashboard" element={<PrivateRoute routeName={"/mentor-dashboard"}><VCDashboard /></PrivateRoute>}></Route>
              <Route path="/mentor-dashboard/:type" element={<PrivateRoute routeName={"/mentor-dashboard/:type"}><VCDashboard /></PrivateRoute>}></Route>
              <Route path="/mentor-dashboard/:tabNumber" element={<PrivateRoute routeName={"/mentor-dashboard/:type"}><VCDashboard /></PrivateRoute>}></Route>
              <Route path="/mentor-dashboard/:type/:type" element={<PrivateRoute routeName={"/mentor-dashboard/:type/:type"}><VCDashboard /></PrivateRoute>}></Route>

              <Route path="/partner-dashboard" element={<PrivateRoute routeName={"/partner-dashboard"}><VCDashboard /></PrivateRoute>}></Route>
              <Route path="/partner-dashboard/:type" element={<PrivateRoute routeName={"/partner-dashboard/:type"}><VCDashboard /></PrivateRoute>}></Route>
              <Route path="/partner-dashboard/:tabNumber" element={<PrivateRoute routeName={"/partner-dashboard/:type"}><VCDashboard /></PrivateRoute>}></Route>
              <Route path="/partner-dashboard/:type/:type" element={<PrivateRoute routeName={"/partner-dashboard/:type/:type"}><VCDashboard /></PrivateRoute>}></Route>

              <Route path="/forms/:type/:type" element={<PrivateRoute><FirstTimeLogin /></PrivateRoute>}></Route>
              <Route path="/profile/:id" element={<PublicProfileView value={value} />}></Route>
              <Route path="/profile/:type/:id" element={<PublicProfileView value={value} />}></Route>

            </Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/forgot-password" element={<Login />}></Route>
            <Route path="/sign-up" element={<Login />}></Route>
            <Route path="/reset-password" element={<Login />}></Route>
            <Route path="/account-verify" element={<Login />}></Route>
            <Route path="/public-view/:id/:id" element={<VCOrMentorProfile />}></Route>
            <Route path="/page-not-found" element={<PageNotFound />}></Route>
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
