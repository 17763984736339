import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getUpdateData } from 'src/pages/accelerate-pages/project-dashboard/API/APICall'
import SectionHeading from '../common/SectionHeading'

export default function NewsSection() {

  const [updates, setUpdates] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    getAllUpdateData()

  }, [])

  // update data
  const getAllUpdateData = async () => {
    setLoader(true)
    await getUpdateData()
      .then((res) => {
        setLoader(false)
        setUpdates(res && res?.data?.values);
      })
      .catch((err) => {
        setLoader(false)
      })
  }

  return (
    <div className='container pt-14'>
      <div className='max-w-[380px] mx-auto grid gap-2 text-center'>
        <SectionHeading heading='Symbiote Portco News' />
      </div>
      <div className='flex gap-10 mt-12'>
        <div className='project-stats-card p-4 relative h-[540px] md:h-[380px] w-full rounded-2xl'>
          <div className='absolute -z-10 w-full h-full bottom-[-5px] right-[-5px] rounded-2xl overflow-hidden'>
            <img src="/img/home/stats-bg.jpg" alt="" className='object-cover w-full h-full' />
          </div>
          <div className='relative z-10 h-full overflow-auto no-scrollbar'>
            {loader ? <div className='h-full flex justify-center items-center'>
              <CircularProgress sx={{ color: 'white' }} size={32} />
            </div>
              : updates.length ? <div className=' grid gap-2'>
                {updates.map((item: any, key) => {
                  return <UpdateCard key={key} title={item?.title} logo={item?.linkedProject?.icon} />
                })}
              </div>
                : <div className='h-full flex justify-center items-center'>
                  <span>No update found</span>
                </div>

            }

          </div>
        </div>
        {/* <div className='project-stats-card h-[560px] md:w-[40%] rounded-2xl'>
          <div className='h-full w-full flex justify-center items-center'>
            <span className='bg-gradient-to-b from-[#FAFAFA] to-[#888888] inline-block text-transparent bg-clip-text text-3xl'>Twitter Feeds</span>
          </div>
        </div> */}
      </div>
    </div>
  )
}


const UpdateCard = (props: { title: string, logo: string }) => {
  return <div>
    <div className='h-[30px]'>
      {props.logo ? <img src={props.logo} className='h-full' alt="" /> : <span className='bg-gray-600 text-black rounded-xl text-sm px-2 font-semibold'>NO LOGO</span>}
    </div>
    <p className='text-sm mt-2'>
      {props.title.split(" ").map(text => {
        if(text.startsWith('http')) {
          return <a href={text} target='_blank'>{text} </a>
        }
        return <span>{text} </span>
      })}
    </p>
  </div>
}