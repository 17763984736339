import { Link, useNavigate } from "react-router-dom"
import CountUp from 'react-countup';
import gsap from "gsap"
import { useLayoutEffect } from "react";
import SplitType from 'split-type'

const symbioteStats = [
  {
    label: 'Project Launches',
    prefix: '',
    suffix: '+',
    countStart: 30,
    value: 50,
    icon: '/img/home/project-icon.svg'
  },
  {
    label: 'Access to capital',
    prefix: '$',
    suffix: 'M+',
    countStart: 150,
    value: 250,
    icon: '/img/home/capital-icon.svg'
  },
  {
    label: 'Partners',
    prefix: '',
    suffix: '+',
    countStart: 280,
    value: 450,
    icon: '/img/home/partner-icon.svg'
  },
  {
    label: 'Community',
    prefix: '',
    suffix: '+',
    countStart: 50,
    value: 110,
    icon: '/img/home/community-icon.svg'
  },
]

export default function TopSection() {

  const navigate = useNavigate()

  useLayoutEffect(() => {

    // animating headings
    const splitHeadingEl = document.querySelectorAll('.heading-text')
    splitHeadingEl.forEach((char: any) => {
      const text = new SplitType(char, { types: 'chars' })

      gsap.from(text.chars, {
        scrollTrigger: {
          trigger: char,
          start: "top center",
          end: "800px center",
          markers: false,
          scrub: false,
          toggleActions: "play reverse play none",
        },
        x: '-100%',
        opacity: 0,
        stagger: 0.03,
      })
    })

    // animating stats cards
    gsap.from('#stats-card', { opacity: 0, x: "100px", duration: 1, height: '0px' })

  }, [])

  return (
    <div className='bg-home-bg bg-top md:bg-center bg-no-repeat bg-contain md:bg-cover'>
      <div className='relative h-[100px] md:h-[120px] flex justify-center items-center pt-12'>
        <img src="/img/home/symbiote-logo.svg" alt="" className="h-full" />
        <div className='hidden md:block absolute right-10'>
          <ul className='list-none flex items-center gap-16 text-white font-light p-0 m-0'>
            <li className='cursor-pointer'>
              <Link to="/blogs" className="no-underline text-white">
                Blogs
              </Link>
            </li>
            <li className='cursor-pointer'>
              <Link to="/events" className="no-underline text-white">
                Events
              </Link>
            </li>
            <li className='cursor-pointer' onClick={() => { window.open("mailto:contact@symbiote.gg") }}>Contact Us</li>
            <li className='cursor-pointer'>
              <Link to="/login" className="no-underline text-white">
                Login
              </Link>
            </li>
            {/* <li className='cursor-pointer'><img src="/img/home/header-menu.svg" alt="" /></li> */}
          </ul>
        </div>
      </div>
      <div className='text-center mt-8 md:mt-12 grid gap-3'>
        <h1 className='heading-text text-[#2aa0f1] text-4xl md:text-[64px] font-bold leading-[1.2]'>Symbiote</h1>
        <h1 className='heading-text text-[#c8c8c8] text-4xl md:text-[76px] font-bold leading-[1.3]'>Supercharging<br />Web3 Startups</h1>
      </div>
      <div className='text-center mt-4 md:mt-2'>
        <p className='text-sm md:text-base font-light'>We don't just accelerate <span className='font-semibold'>We 100x.</span></p>
      </div>
      <div className='mt-5 text-center'>
        <button
          className='home-apply-btn'
          onClick={() => { navigate(`/contact-form/project-ahead`) }}
        >
          <span className="text-[12px] md:text-[14px]">Apply for the program</span>
        </button>
      </div>
      <div>
        <div
          className='container px-8 md:px-0 grid grid-cols-2 lg:grid-cols-4 mt-20 mb-10 gap-8 md:gap-14'>
          {symbioteStats.map((item, index) => {
            return <StatsCard
              key={index}
              prefix={item.prefix}
              suffix={item.suffix}
              countStart={item.countStart}
              label={item.label}
              value={item.value}
              icon={item.icon}
            />
          })}
        </div>
      </div>
    </div>
  )
}

function StatsCard(props: { label: string, value: number, countStart: number, prefix: string, suffix: string, icon: string }) {

  return <div className='relative' id="stats-card">
    <img className='absolute left-[-18px] top-[-30px] md:top-[-40px] z-0 scale-110 w-[60px] h-[60px] md:w-[80px] md:h-[80px] object-contain' src={props.icon} alt="" />
    <div className='relative z-10 overflow-hidden rounded-xl' style={{ border: '1px solid rgba(255, 255, 255, 0.09)' }}>
      <div className='absolute z-20 w-full h-full bg-[#26262641]' />
      <div className='p-2 py-4 md:p-6 backdrop-blur-[2px]'>
        <h3 className='bg-gradient-to-b from-[#FAFAFA] to-[#888888] inline-block text-transparent bg-clip-text text-2xl md:text-5xl font-bold'>
          {props.prefix}<CountUp start={props.countStart} end={props.value} duration={5} />{props.suffix}
        </h3>
        <p className='text-xs md:text-base font-light p-0 m-0'>{props.label}</p>
      </div>
    </div>
  </div>
}

